<template>
  <div class="dialog-wrap" v-show="showWin">
    <div class="modal"></div>
    <div class="dialog">
      <div class="content-wrap">
        <div class="title">購買會員</div>
        <img src="../assets/img/viptext.png" class="viptext" srcset="" />
        <img
          src="../assets/img/vipclose.png"
          class="close"
          srcset=""
          @click="closeWin"
        />
        <div class="vip_list">
          <div
            class="vip_item"
            :class="{ vip_act: buyIndex == 0 }"
            @click="switchIndex(0)"
          >
            <div class="vip_title">會員時長： 1 D＝24 H</div>
            <div class="buy_num" v-if="sales.length">
              {{ sales[0].showPrice
              }}<span class="unit">{{ priceSymbol }}</span>
            </div>
            <img src="../assets/img/vipa.png" class="vipa" srcset="" />
          </div>
          <div
            class="vip_item"
            :class="{ vip_act: buyIndex == 1 }"
            @click="switchIndex(1)"
          >
            <div class="vip_title">會員時長：7 D＝168 H</div>
            <div class="buy_num" v-if="sales.length">
              {{ sales[1].showPrice
              }}<span class="unit">{{ priceSymbol }}</span>
            </div>
            <img src="../assets/img/vipa.png" class="vipa" srcset="" />
          </div>
          <div
            class="vip_item"
            :class="{ vip_act: buyIndex == 2 }"
            @click="switchIndex(2)"
          >
            <div class="vip_title">永久 Block Change Tools 會員</div>
            <div class="buy_num" v-if="sales.length">
              {{ sales[2].showPrice
              }}<span class="unit">{{ priceSymbol }}</span>
            </div>
            <img src="../assets/img/vipa.png" class="vipa" srcset="" />
          </div>
        </div>
        <div class="amount_text">餘額：{{ showBalance }} {{ priceSymbol }}</div>
        <div class="buy_btn" @click="buyVip">確認購買</div>
        <div class="invite_box">
          <span class="copy_ctn">{{
            isVip ? shareUrl : "购买后才可以邀请人"
          }}</span>
          <img src="../assets/img/copy.png"  @click="clipboardSuccess($event, shareUrl)" class="copy_icon" srcset="" />
        </div>
        <div class="buy_desc">*邀請人購買後會員將立即獲得10%的BNB獎勵</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import BN from "bn.js";
import {
  CheckVipRpc,
  vipChainId,
  VipSale,
  VipSale_ABI,
  ZERO_ADDRESS,
  MAX_INT,
} from "../config";
import clip from "../utils/clipboard";
import { showFromWei, parseTime } from "../utils";
export default {
  props: {
    address: {
      type: String,
    },
    chainId: {
      type: [String, Number],
    },
  },
  computed: {
    showWin() {
      return this.$store.state.app.showWin;
    },
    isVip() {
      return this.$store.state.app.isVip;
    },
    shareUrl() {
      return "https://tool.blockchange.dev?ref=" + this.address;
    },
  },
  watch: {
    address() {
      this.getInfo();
    },
  },
  data() {
    return {
      priceDecimals: "",
      priceSymbol: "",
      blockTime: "",
      sales: [],
      amount: "",
      showAmount: "",
      endTime: "",
      showEndTime: "",
      balance: "",
      showBalance: "",

      buyIndex: 0,
    };
  },
  methods: {
    closeWin() {
      this.$store.dispatch("setWin", false);
    },
    switchIndex(index) {
      this.buyIndex = index;
    },
    //购买Vip
    async buyVip() {
      if (vipChainId != this.chainId) {
        this.$message.error(this.$t("vip.6"));
        return;
      }
      let account = this.address;
      if (!account) {
        return;
      }
      let index = this.buyIndex;
      if (-1 == index) {
        this.$message.error(this.$t("vip.7"));
        return;
      }
      let sale = this.sales[index];
      //需要的代币价格
      let price = new BN(sale.price, 10);
      let balance = new BN(this.balance, 10);
      if (balance.lt(price)) {
        this.$message.error(
          this.priceSymbol + this.$t("lang.balanceNotEnough")
        );
        return;
      }

      
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
        const web3 = window.web3;

        const saleContract = new web3.eth.Contract(VipSale_ABI, VipSale);
        let invitor = this.getRef();
        if (!invitor) {
          invitor = ZERO_ADDRESS;
        }
        var transaction = await saleContract.methods
          .buy(index, invitor)
          .send({ from: account, value: price });

        if (transaction.status) {
          this.$message(this.$t("lang.success1"));
        } else {
          this.$message.error(this.$t("lang.fail1"));
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.getInfo();
        this.message.close()
        this.$store.dispatch("setWin", false);
      }
    },
    async getInfo() {
      try {
        //  let options = {
        // timeout: 600000, // milliseconds,
        // headers: [{ name: "Access-Control-Allow-Origin", value: "*" }],
        // };
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(CheckVipRpc));
        const saleContract = new myWeb3.eth.Contract(VipSale_ABI, VipSale);
        //获取基本信息
        let baseInfo = await saleContract.methods.shopInfo().call();
        //价格精度
        let priceDecimals = parseInt(baseInfo[0]);
        //价格符号
        let priceSymbol = baseInfo[1];
        //当前区块时间
        let blockTime = parseInt(baseInfo[2]);
        //共收入多少
        let totalAmount = baseInfo[3];
        //邀请奖励共多少
        let totalInviteAmount = baseInfo[4];

        //销售列表
        const allSales = await saleContract.methods.allSales().call();
        //价格
        let prices = allSales[0];
        //有效期
        let durations = allSales[1];

        let sales = [];
        let len = prices.length;
        for (let i = 0; i < len; ++i) {
          let price = prices[i];
          let duration = durations[i];
          let showDuration;
          if (new BN(duration, 10).eq(new BN(MAX_INT, 10))) {
            showDuration = "永久";
          } else {
            showDuration = parseInt(duration) / 86400 + "天";
          }
          sales.push({
            price: price,
            showPrice: showFromWei(price, priceDecimals, 6),
            duration: duration,
            showDuration: showDuration,
          });
        }

        console.log(priceSymbol);
        console.log(sales);
        this.priceDecimals = priceDecimals;
        this.priceSymbol = priceSymbol;
        this.blockTime = blockTime;
        this.sales = sales;

        let account = this.address;
        if (account) {
          //获取用户信息
          const userInfo = await saleContract.methods
            .getUserInfo(account)
            .call();
          //购买Vip共消费多少
          let amount = userInfo[0];
          //Vip过期时间
          let endTime = userInfo[1];
          //余额
          let balance = userInfo[2];

          let showEndTime,
            isVip = true;
            
            console.log(endTime)
          if (new BN(endTime, 10).eq(new BN(MAX_INT, 10))) {
            showEndTime = "永久会员";
          } else if (parseInt(endTime) == 0) {
            showEndTime = "未购买会员";
            isVip = false;
          } else {
            showEndTime = parseTime(parseInt(endTime));
            if(parseInt(new Date().getTime() / 1000) > parseInt(endTime)) {
              isVip = false
            }
          }
          
          this.$store.dispatch("setVip", isVip);

          this.amount = amount;
          this.showAmount = showFromWei(amount, priceDecimals, 6);
          this.endTime = endTime;
          this.showEndTime = showEndTime;
          this.$store.dispatch("setEndTime", showEndTime);
          this.balance = balance;
          this.showBalance = showFromWei(balance, priceDecimals, 6);
        }
      } catch (e) {
        console.log("getInfo", e.message);
        this.$message.error(e.message);
      } finally {
      }
    },
    //获取邀请人
    getRef() {
      //先从链接获取，如果有，直接使用
      var url = window.location.href;
      var obj = new Object();
      var scan_url = url.split("?");
      if (2 == scan_url.length) {
        scan_url = scan_url[1];
        var strs = scan_url.split("&");
        for (var x in strs) {
          var arr = strs[x].split("=");
          obj[arr[0]] = arr[1];
          //链接里有邀请人
          if ("ref" == arr[0] && arr[1]) {
            return arr[1];
          }
        }
      }
      //从浏览器缓存获取，这里可能部分浏览器不支持
      var storage = window.localStorage;
      if (storage) {
        return storage["ref"];
      }
      return null;
    },
    clipboardSuccess(event, text) {
      if(!this.isVip) return
      var that = this;
      clip(text, event, function () {
        that.$Message.success(that.$t("lang.success1"));
      });
    },
  },
  mounted() {
    if (this.address) {
      this.getInfo();
    }
  },
};
</script>
  
  <style lang="less">
.dialog-wrap {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .modal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .dialog {
    position: absolute;
    margin: 0 auto;
    top: 5vw;
    left: 50%;
    width: 440px;
    margin-left: -220px;
    background: #fff;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #d0e2e4;
  }
  .content-wrap {
    position: relative;
    padding: 40px 32px;
    height: 100%;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      margin-bottom: 8px;
    }
    .viptext {
      height: 12px;
      margin-bottom: 38px;
    }
    .close {
      position: absolute;
      top: 32px;
      right: 40px;
      width: 28px;
      height: 28px;
    }
    .vip_item {
      position: relative;
      padding: 28px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #d0e2e4;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .vip_title {
        margin-bottom: 12px;
        font-size: 17px;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
      .buy_num {
        font-size: 24px;
        font-weight: 500;
        color: #f2994a;
        line-height: 28px;
      }
      .unit {
        font-size: 17px;
        margin-left: 4px;
      }
      .vipa {
        display: none;
        position: absolute;
        bottom: 28px;
        right: 0;
        width: 144px;
        height: 144px;
      }
    }
    .vip_act {
      background: rgba(254, 202, 0, 0.2);
      border: 1px solid #f2994a;
      .vipa {
        display: block;
      }
    }
    .amount_text {
      color: #2980ff;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .buy_btn {
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #2980ff;
      border-radius: 6px 6px 6px 6px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .invite_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      margin-top: 38px;
      .copy_ctn {
        color: rgba(56, 56, 56, 0.5);
        font-size: 12px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .copy_icon {
        flex-shrink: 0;
        width: 16px;
      }
    }
    .buy_desc {
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      line-height: 14px;
      margin-top: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .dialog-wrap {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  .modal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .dialog {
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #d0e2e4;
  }
  .content-wrap {
    position: relative;
    padding: 40px 32px;
    height: auto;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      margin-bottom: 8px;
    }
    .viptext {
      height: 12px;
      margin-bottom: 38px;
    }
    .close {
      position: absolute;
      top: 32px;
      right: 40px;
      width: 28px;
      height: 28px;
    }
    .vip_item {
      position: relative;
      padding: 28px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #d0e2e4;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .vip_title {
        margin-bottom: 12px;
        font-size: 17px;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }
      .buy_num {
        font-size: 24px;
        font-weight: 500;
        color: #f2994a;
        line-height: 28px;
      }
      .unit {
        font-size: 17px;
        margin-left: 4px;
      }
      .vipa {
        display: none;
        position: absolute;
        bottom: 28px;
        right: 0;
        width: 144px;
        height: 144px;
      }
    }
    .vip_act {
      background: rgba(254, 202, 0, 0.2);
      border: 1px solid #f2994a;
      .vipa {
        display: block;
      }
    }
    .amount_text {
      color: #2980ff;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .buy_btn {
      height: 46px;
      line-height: 46px;
      text-align: center;
      background: #2980ff;
      border-radius: 6px 6px 6px 6px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .invite_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      margin-top: 38px;
      .copy_ctn {
        color: rgba(56, 56, 56, 0.5);
        font-size: 12px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .copy_icon {
        flex-shrink: 0;
        width: 16px;
      }
    }
    .buy_desc {
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      line-height: 14px;
      margin-top: 14px;
    }
  }
}
}
</style>