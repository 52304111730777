<template>
  <div class="page_content">
    <div class="page_menu">
      <div class="menu_inner">
        <div class="logo_box">
          <img src="../assets/img/logo.png" alt="" srcset="" />
        </div>
        <div class="time_box">
          <img src="../assets/img/time_text.png" class="text" srcset="" />
          <div class="time_text"  @click="clickBuyModal">
            <img src="../assets/img/time_icon.png" class="icon" srcset="" />
            到期時間：{{ showEndTime }}
          </div>
        </div>
        <div class="menu_list">
          <router-link to="/scientist">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/scientist' }"
            >
              <img
                src="../assets/img/menu1.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu1a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.scientist") }}
            </div>
          </router-link>
          <router-link to="/sale">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/sale' }"
            >
              <img
                src="../assets/img/menu2.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu2a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.sale") }}
            </div>
          </router-link>
          <router-link to="/order">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/order' }"
            >
              <img
                src="../assets/img/menu3.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu3a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.order") }}
            </div>
          </router-link>
          <router-link to="/transfer">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/transfer' }"
            >
              <img
                src="../assets/img/menu4.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu4a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.transfer") }}
            </div>
          </router-link>
          <router-link to="/collect">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/collect' }"
            >
              <img
                src="../assets/img/menu7.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu7a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.collect") }}
            </div>
          </router-link>
          <router-link to="/contract">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/contract' }"
            >
              <img
                src="../assets/img/menu8.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu8a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.contract") }}
            </div>
          </router-link>
          <router-link to="/trade">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/trade' }"
            >
              <img
                src="../assets/img/menu9.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu9a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.trade") }}
            </div>
          </router-link>
          <router-link to="/gas">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/gas' }"
            >
              <img
                src="../assets/img/menu6.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu6a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.gas")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
          <router-link to="/wallets">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/wallets' }"
            >
              <img
                src="../assets/img/menu5.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu5a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.wallets")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
          <router-link to="/address">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/address' }"
            >
              <img
                src="../assets/img/menu10.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu10a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.address")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="menu_bottom">
        <div>
          <a href="https://t.me/BC_BlockChange" target="_blank" rel="noopener noreferrer">
            <img src="../assets/img/tg.png" class="icon" srcset="" />
          </a>
          <a href="https://twitter.com/BlockChange_" target="_blank" rel="noopener noreferrer">
            <img src="../assets/img/tw.png" class="icon" srcset="" />
          </a>
        </div>
        <a href="mailto:Support@BlockChange.Dev" style="text-decoration: none">
          <div class="mailto">
            <img src="../assets/img/msg.png" class="msg" srcset="" />
            <div class="contact_text">
              <span class="contact">Contact us&nbsp;/&nbsp;</span
              >Support@BlockChange.Dev
            </div>
          </div>
        </a>
        <div class="copy_right">Copyright © 2023 Block Change</div>
      </div>
    </div>
    <i-drawer
      class-name="menu_drawer"
      placement="left"
      :closable="false"
      v-model="menu"
    >
      <div class="page_menu page_menu_m">
        <div class="menu_inner">
        <div class="logo_box">
          <img src="../assets/img/logo.png" alt="" srcset="" />
        </div>
        <div class="time_box">
          <img src="../assets/img/time_text.png" class="text" srcset="" />
          <div class="time_text" @click="clickBuyModal">
            <img src="../assets/img/time_icon.png" class="icon" srcset="" />
            到期時間：{{ showEndTime }}
          </div>
        </div>
        <div class="menu_list">
          <router-link to="/scientist" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/scientist' }"
            >
              <img
                src="../assets/img/menu1.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu1a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.scientist") }}
            </div>
          </router-link>
          <router-link to="/sale" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/sale' }"
            >
              <img
                src="../assets/img/menu2.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu2a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.sale") }}
            </div>
          </router-link>
          <router-link to="/order" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/order' }"
            >
              <img
                src="../assets/img/menu3.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu3a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.order") }}
            </div>
          </router-link>
          <router-link to="/transfer" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/transfer' }"
            >
              <img
                src="../assets/img/menu4.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu4a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.transfer") }}
            </div>
          </router-link>
          <router-link to="/collect" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/collect' }"
            >
              <img
                src="../assets/img/menu7.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu7a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.collect") }}
            </div>
          </router-link>
          <router-link to="/contract" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/contract' }"
            >
              <img
                src="../assets/img/menu8.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu8a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.contract") }}
            </div>
          </router-link>
          <router-link to="/trade" @click.native="menu = false">
            <div
              class="menu_item"
              :class="{ menu_active: $route.path == '/trade' }"
            >
              <img
                src="../assets/img/menu9.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu9a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.trade") }}
            </div>
          </router-link>
          <router-link to="/gas" @click.native="menu = false">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/gas' }"
            >
              <img
                src="../assets/img/menu6.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu6a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.gas")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
          <router-link to="/wallets" @click.native="menu = false">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/wallets' }"
            >
              <img
                src="../assets/img/menu5.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu5a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.wallets")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
          <router-link to="/address" @click.native="menu = false">
            <div
              class="menu_item menu_border"
              :class="{ menu_active: $route.path == '/address' }"
            >
              <img
                src="../assets/img/menu10.png"
                class="menu_icon normal"
                srcset=""
              />
              <img
                src="../assets/img/menu10a.png"
                class="menu_icon active"
                srcset=""
              />
              {{ $t("title.address")
              }}<img src="../assets/img/free.png" class="free" srcset="" />
            </div>
          </router-link>
        </div>
        </div>
          <img src="../assets/img/menu_bt.png" class="menu_bt" srcset="">
        <div class="menu_bottoma">
          <div>
            <a href="https://t.me/BC_BlockChange" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/tg.png" class="icon" srcset="" />
            </a>
            <a href="https://twitter.com/BlockChange_" target="_blank" rel="noopener noreferrer">
              <img src="../assets/img/tw.png" class="icon" srcset="" />
            </a>
          </div>
          <a
            href="mailto:Support@BlockChange.Dev"
            style="text-decoration: none"
          >
            <div class="mailto">
              <img src="../assets/img/msg.png" class="msg" srcset="" />
              <div class="contact_text">
                <span class="contact">Contact us&nbsp;/&nbsp;</span
                >Support@BlockChange.Dev
              </div>
            </div>
          </a>
          <div class="copy_right">Copyright © 2023 Block Change</div>
        </div>
      </div>
    </i-drawer>
    <div class="page_main" ref="pageMain">
      <div class="common_title" :class="{'topNavBg':topNavBg}">
        <div class="left">
          <h2>{{ $t("title_head." + $route.meta.title) }}</h2>
        </div>
        <div class="logo_box">
          <img
            src="../assets/img/menu.png"
            @click="menu = !menu"
            class="menu"
            srcset=""
          />
          <img src="../assets/img/logo2.png" class="logo" srcset="" />
        </div>
        <div class="right">
          <div class="wallet_box" v-show="!address">
            <img src="../assets/img/wallet.png" alt="" srcset="" />Connect
          </div>
          <div class="wallet_box" v-show="address">
              {{ addressText }}
          </div>
          <div class="vip_box" @click="clickBuyModal">
            <img src="../assets/img/zuanshi.png" alt="" srcset="" />Premium
          </div>
          <div class="lang_box" @click="switchLang">
            {{ $i18n.locale == "ch" ? "Cn" : "En" }}
          </div>
        </div>
      </div>
      <div class="page_inner">
      <h2 class="mobile_h2">{{ $t("title_head." + $route.meta.title) }}</h2>
      <div class="tips_box" v-show="$route.path != '/trade'&&$route.path != '/transfer'&&$route.path != '/contract'">
        <div class="tips" v-show="$route.path != '/address' && $route.path != '/wallets'">
          <img
            src="../assets/img/info.png"
            alt=""
            srcset=""
          />本程序完全本地運行,不會上傳私鑰到服務器,歡迎監督檢測.
        </div>
        <div class="tips2" v-show="$route.path == '/address' || $route.path == '/wallets'">
          <img
            src="../assets/img/tanhao.png"
            alt=""
            srcset=""
          />在生成钱包前，请断開絪络使用
        </div>
      </div>
      <div class="tips_box2" v-show="$route.path == '/trade'">
        <div class="tips_label">主要用於代幣在DEX上進行自動買賣增加交易量</div>
        <div class="tips">
          <img
            src="../assets/img/info.png"
            alt=""
            srcset=""
          />本程序完全本地運行,不會上傳私鑰到服務器,歡迎監督檢測.
        </div>
      </div>
      <div class="tips_box2" v-show="$route.path == '/contract'">
        <div class="tips_label">多地址進行鏈上合約接口調用（領空投、認購等等）</div>
        <div class="tips">
          <img
            src="../assets/img/info.png"
            alt=""
            srcset=""
          />本程序完全本地運行,不會上傳私鑰到服務器,歡迎監督檢測.
        </div>
      </div>
      <div class="top_right"></div>
      <div class="bottom_left"></div>
      <router-view :address="address" :chainId="chainId" />
    </div>
    </div>
    <buyVip
      ref="modal"
      :showWin="false"
      :address="address"
      :chainId="chainId"
    ></buyVip>
  </div>
</template>

<script>
import buyVip from "../components/buyVip.vue";
import { vipChainId } from "../config";

export default {
  components: {
    buyVip,
  },
  data() {
    return {
      topNavBg: false,
      menu: false,
      address: null,
      chainId: null,
      walletStatus: "CONNECT",
    };
  },
  computed: {
    addressText() {
      if (this.address) {
        return this.address.slice(0, 4) + "..." + this.address.substr(-4);
      }
      return this.address;
    },
    showEndTime() {
      return this.$store.state.app.showEndTime;
    },
    isVip() {
      return this.$store.state.app.isVip;
    },
    xqBox() {
      return this.$refs.pageMain;
    }
  },
  mounted() {
    this.$nextTick(() => {
      // //监听这个dom的scroll事件
      // this.xqBox.onscroll = () => {
      //   console.log("on scroll");
      //   this.handleScroll();
      // };
      //监听这个dom的scroll事件
      this.xqBox.addEventListener("scroll", this.handleScroll);
    });
    this.walletInstalled();
  },
  beforeDestroy() {
    this.xqBox.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 滚动页面时触发导航变色
	    handleScroll () {
	        let scrollTop = this.xqBox.scrollTop
	        // 设置背景颜色的透明度
	        if (scrollTop > 100) {
	            this.topNavBg = true
	      	} else if (scrollTop < 100) {
	        	this.topNavBg = false
	      	}
	    },
    async walletInstalled() {
      const metamaskInstalled = typeof window.web3 !== "undefined"; //boolean value return
      if (metamaskInstalled) {
        //connect to wallet
        await this.connectWallet();
        //load data if wallet connected
        await this.loadData();
      } else {
        // alert("Please install metamask");
      }
    },
    async connectWallet() {
      if (this.walletStatus == "CONNECTED") return;
      var Web3 = require("web3");
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        // ask permission to connect
        try {
          await window.ethereum.enable();
          this.walletStatus = "CONNECTED";
        } catch (err) {
          this.walletStatus = "CONNECT";
        }
      } else if (window.web3) {
        //create a new connection to blockchain
        window.web3 = new Web3(window.web3.currentProvider);
      }
    },
    async loadData() {
      const web3 = window.web3;
      var account = await web3.eth.getCoinbase();
      this.address = account;
      var chainId = await web3.eth.getChainId();
      this.chainId = chainId;
      ethereum.on("accountsChanged", (accounts) => {
        this.address = accounts[0];
      });
      //监听链变化
      ethereum.on("chainChanged", (chainId) => {
        this.chainId = chainId;
      });
    },

    clickBuyModal() {
      if (this.chainId != vipChainId) {
        this.$message.error("请连接BSC链钱包购买VIP");
        return;
      }
      this.$store.dispatch("setWin", true);
      this.$refs.modal.getInfo();
    },

    switchLang() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "ch";
        localStorage.setItem("lang", "ch");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/layout.less";
@import "../assets/mobile/layout.less";
</style>